<template>
  <div class="container">
    <header-register :showButtonView=false>DCB
    </header-register>
    <formAddGeral />
  </div>
</template>

<script>
import formAddGeral from "@/components/dcb/formEdit/formEditGeral.vue";
import HeaderRegister from "@/@core/components/register/HeaderRegister.vue";

export default {
  name: "editDcb",
  components: {
    HeaderRegister,
    formAddGeral,
  },
};
</script>


<style>
.title-prescritor {
  font-weight: 900;
  font-size: 2.5em;
  color: #cf0209;
  font-family: "Segoe_UI_Black_2";
  letter-spacing: 1px;
}

/*  Mobile  */

@media (max-width: 992px) {
  .title-dcb {
    font-size: 2em;
  }
}
</style>